import { createTheme } from '@mui/material/styles';

export const getLightTheme = (primaryColor?: string, secondaryColor?: string) => {
  return createTheme({
    palette: {
      mode: 'light',
      primary: {
        // light: '#bdbdbd',
        main: primaryColor ? primaryColor : '#012D4A',
        // dark: '#757575',
      },
      secondary: {
        main: secondaryColor ? secondaryColor : '#008A9F',
      },
      text: {
        primary: '#111',
        secondary: '#525252',
      },
    },
    typography: {
      fontFamily: `'Montserrat', sans-serif`,
      h1: {
        fontSize: '35px',
        fontWeight: 700,
        lineHeight: 1.4,
      },
      h2: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: 1.4,
      },
      h3: {
        fontSize: '25px',
        fontWeight: 700,
        lineHeight: 1.4,
      },
      h4: {
        fontSize: '22px',
        fontWeight: 600,
        lineHeight: 1.4,
      },
      h5: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: 1.4,
      },
      h6: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 1.4,
      },
      body1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: 1.4,
      },
      subtitle1: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 1.4,
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          color: '#212121',
        },
      },
      MuiBadge: {
        defaultProps: {
          color: 'info',
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 10,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '& .MuiSvgIcon-root': {
              borderRadius: 10,
              borderWidth: 1,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            background: '#f1f1f1',
            '& .MuiInputBase-root': {
              borderRadius: 10,
              boxShadow: '5px 4px 1px #012D4A',
              '& fieldset': {
                borderColor: '#012D4A'
              },
              '& label': {
                fontSize: '12px'
              }
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            '&.MuiInputLabel-shrink': {
              fontSize: '16px'
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            boxShadow: '0 0 5px #dadada'
          }
        }
      }
    },
  });
};
