import { Reducer } from '@reduxjs/toolkit';
import { PlanActionTypes, PlanActions } from '../actions/plan.actions';

export interface PlanState {
  planInfo: {
    plan: {},
  };
}

const initialState: PlanState = {
  planInfo: {
    plan: {},
  },
};

export const planReducer: Reducer<PlanState, any> = (
  state: PlanState = initialState,
  action: PlanActions
): PlanState => {
  switch (action.type) {
    case PlanActionTypes.SET_EMPLOYER_PLAN_INFO:
      return {
        ...state,
        planInfo: action.payload,
      };
    default:
      return state;
  }
};
