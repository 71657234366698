export enum ChatActionTypes {
  CHANGE_USER = 'CHANGE_USER',
  UNREAD_MESSAGES = 'UNREAD_MESSAGES'
}

interface ChangeUserAction {
  type: ChatActionTypes.CHANGE_USER;
  payload: any;
}

interface UnreadMessagesAction {
  type: ChatActionTypes.UNREAD_MESSAGES;
  payload: any;
}

export type ChatActions = ChangeUserAction | UnreadMessagesAction;
