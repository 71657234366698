import { User } from "../../types/auth.interface";

export enum AuthActionTypes {
  SET_EMPLOYER_INFO = 'SET_EMPLOYER_INFO',
  SET_UPDATED_USER = 'SET_UPDATED_USER'
}

interface SetUserAction {
  type: AuthActionTypes.SET_EMPLOYER_INFO;
  payload: any;
}

interface SetUpdatedUserAction {
  type: AuthActionTypes.SET_UPDATED_USER;
  payload: User;
}

export type AuthActions = SetUserAction | SetUpdatedUserAction;
