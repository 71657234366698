import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { commonReducer } from './reducers/common.reducer';
import { authReducer } from './reducers/auth.reducer';
import { chatReducer } from './reducers/chat.reducer';
import { planReducer } from './reducers/plan.reducer';

const reducer = {
  commonState: commonReducer,
  authState: authReducer,
  chatState: chatReducer,
  planState: planReducer
};

const store = configureStore({
  reducer,
  middleware: [thunk],
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
