import { useDispatch } from 'react-redux';
import { CommonActionTypes, CommonActions } from '../store/actions/common.actions';
import { ToastInfo, toastInitialState } from '../types/common.interface';

export const useToast = () => {
  const dispatch = useDispatch();

  const displayToast = (toast: ToastInfo) => {
    dispatch<CommonActions>({
      type: CommonActionTypes.SHOW_TOAST,
      payload: toast,
    });
  };

  const clearToast = () => {
    dispatch<CommonActions>({
      type: CommonActionTypes.REMOVE_TOAST,
      payload: toastInitialState,
    });
  };

  return { displayToast, clearToast } as const;
};
