import { Suspense, useEffect } from 'react';
import './App.scss';
import { ThemeProvider } from '@mui/material';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import { getLightTheme } from './theme';
import React from 'react';
import Toast from './components/common/toast';
import { setAuthToken } from './utils/set-token';

const Auth = React.lazy(() => import('./views/auth'));
const Main = React.lazy(() => import('./views/main'));

function App() {
  const employerInfo = JSON.parse(localStorage.getItem('employerInfo') || '{}');
  // const dispatch = useDispatch();
  // if (process.env.NODE_ENV == 'production') {
  //   console.log(process.env.REACT_APP_DEV_API_BASE_URL); 
  // } else {
  //   console.log(process.env.REACT_APP_LOCAL_API_BASE_URL); 
  // }

  useEffect(() => {
    if (employerInfo?.accessToken) {
      setAuthToken(employerInfo.accessToken);
      // dispatch<AuthActions>({
      //   type: AuthActionTypes.SET_USER_INFO,
      //   payload: userInfo,
      // });
    }
  }, []);
  return (
    <BrowserRouter>
      <ThemeProvider theme={getLightTheme()}>
        <Toast />
        <Suspense fallback={'Loading...'}>
          <Routes>
            <Route path="/*" element={<Main />} />
            {/* <Route path="/auth/*" element={<Auth />} />
            <Route path="" element={<Navigate replace to="/auth/signin" />} /> */}
            <Route path="/auth/*" element={<Auth />} />
            <Route path="" element={<Navigate replace to="/auth/signin" />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
