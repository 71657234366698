import { Reducer } from '@reduxjs/toolkit';
import { ChatActionTypes, ChatActions } from '../actions/chat.actions';

export interface ChatState {
  student: any;
  unreadMessages: any;
}

const initialState: ChatState = {
  student: {},
  unreadMessages: {}
};

export const chatReducer: Reducer<ChatState, any> = (
  state: ChatState = initialState,
  action: ChatActions
): ChatState => {
  switch (action.type) {
    case ChatActionTypes.CHANGE_USER:
      return {
        ...state,
        student: action.payload,
      };
    case ChatActionTypes.UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: action.payload,
      };  
    default:
      return state;
  }
};
