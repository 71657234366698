
export enum PlanActionTypes {
  SET_EMPLOYER_PLAN_INFO = 'SET_EMPLOYER_PLAN_INFO',
}

interface SetPlanDetailsAction {
  type: PlanActionTypes.SET_EMPLOYER_PLAN_INFO;
  payload: {plan: {}};
};

export type PlanActions = SetPlanDetailsAction;