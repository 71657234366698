import { Box, styled } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../http-common';
const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 5,
  borderRadius: 5,
}));
const GlobalLoader = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const reqInterceptor = axiosInstance.interceptors.request.use(
      function (config) {
        setLoading(true);
        return config;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );
    const resInterceptor = axiosInstance.interceptors.response.use(
      function (response) {
        setLoading(false);
        return response;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );
    return () => {
      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.response.eject(resInterceptor);
    };
  }, []);
  return (
    <div>
      {loading && (
        <Box
          sx={{ flexGrow: 1, background: 'rgba(255,255,255,.7)' }}
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex={10}
        >
          <BorderLinearProgress sx={{height: '10px', borderRadius: 0}} className="global-loader" />
        </Box>
      )}
    </div>
  );
};

export default GlobalLoader;
